import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Table, Button, Modal } from "antd";
import { Dropdown } from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FileTextOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRFQDataAction,
  getRequestQuotationList,
} from "../../appRedux/actions/requestQuotationAction";
import { formatDate, isBuyer, isExporter } from "../../util/helper";
import { isArrayNonEmpty, isValidElement } from "../../util/ApiHelper";
import RFQStatusTags from "./RFQStatus";
import { BUSINESS_TYPE_LABEL, RFQStatus } from "../../util/Constant";
import ListEmptyComponent from "../../components/ListEmptyComponent/ListEmptyComponent";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";
import { ItemDetailMobile } from "../../components/ItemDetailMobile";

const getStatus = (data) => {
  return data.totalQuotations > 0 ? RFQStatus.QUOTATION_RECEIVED : data.status
}

const { Meta } = Card;

const RFQList = () => {
  const dispatch = useDispatch();
  const { isMobile } = useGetBreakpoints();
  const userData = useSelector(({ auth }) => auth?.userData);
  const RFQList = useSelector(
    (state) => state.requestQuotations.requestQuotationList
  );
  const { loading } = useSelector(({ common }) => common);

  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    dispatch(getRequestQuotationList());
  }, [dispatch]);

  const onShowSizeChange = useCallback((current, pageSize) => {
    setPageSize(pageSize);
    dispatch(getRequestQuotationList(current, pageSize));
  }, [dispatch]);

  const history = useHistory();
  const navigateToDetails = useCallback((id, isClone = false) => {
    if (isValidElement(id)) {
      if (isClone) {
        history.push(`rfq/clone/${id}`);
      } else {
        history.push(`rfq/view/${id}`);
      }
    } else {
      history.push("rfq/details");
    }
  }, [history]);

  const navigateToQuotationsList = useCallback((id) => {
    history.push(`rfq/quotations-list/${id}`);
  }, [history]);

  const handleOnREQIconClick = useCallback(() => {
    dispatch(deleteRFQDataAction(deleteItem));
    handleCancelModel();
  }, [deleteItem, dispatch]);

  const handleCancelModel = () => {
    setShowDeleteModel(false);
    setDeleteItem(null);
  };

  const showDeleteItemModel = useCallback((deleteItem) => {
    setShowDeleteModel(true);
    setDeleteItem(deleteItem);
  }, []);

  const moreActionsRenderer = useCallback((record) => (
    <div className="myexim-action-icon" onClick={(e) => e.stopPropagation()}>
      <Dropdown
        menu={{
          items: [
            {
              label: "View Details",
              key: "1",
              icon: <EditOutlined />,
              onClick: () => {
                navigateToDetails(record?.id);
              },
            },
            record?.status !== RFQStatus.DRAFT &&
              record?.totalQuotations > 0 && {
                label: "View Quotations",
                key: "2",
                icon: <FileTextOutlined />,
                onClick: () => {
                  navigateToQuotationsList(record?.id);
                },
              },
            record?.status !== RFQStatus.DRAFT &&
              record?.status !== RFQStatus.PENDING_APPROVAL &&
              record?.status !== RFQStatus.REJECTED && {
                label: "Clone",
                key: "3",
                icon: <CopyOutlined />,
                onClick: () => {
                  navigateToDetails(record?.id, true);
                },
              },
            {
              label: "Delete",
              key: "4",
              icon: <DeleteOutlined />,
              onClick: () => {
                showDeleteItemModel(record?.id);
              },
            },
          ],
        }}
        trigger={["click"]}
      >
        <MoreOutlined />
      </Dropdown>
    </div>
  ), [navigateToDetails, navigateToQuotationsList, showDeleteItemModel])

  const columns = useMemo(() => [
    {
      title: "Posted Date",
      key: "createdAt",
      width: "150px",
      render: (record) => <div>{formatDate(record?.createdAt)}</div>,
    },
    {
      title: "Prodcut Category",
      key: "categoryName",
      width: "150px",
      render: (record) => {
        return <div>{record?.categories?.name}</div>;
      },
    },
    {
      title: "Product Name",
      key: "Product Name",
      width: "150px",
      render: (record) => <div>{record?.productName}</div>,
    },
    {
      title: " Required Quantity",
      key: "quantityRequired",
      width: "150px",
      render: (record) => (
        <div>
          {record?.qty
            ? `${record.qty} ${record.units || ""}`
            : `${record.noOfContainers || ""} ${
                record.noOfContainers > 1 ? "Containers" : "Container"
              }`}
        </div>
      ),
    },
    {
      title: "Party Name",
      key: "targetedBusinessType",
      width: "150px",
      render: (record) => {
        return (
          <div>
            {isArrayNonEmpty(record?.targetedBusinessType)
              ? BUSINESS_TYPE_LABEL[record?.targetedBusinessType[0]]
              : "-"}
          </div>
        );
      },
    },
    {
      title: "Number of Received Quotations",
      key: "totalQuotations",
      render: (record) => <div>{record?.totalQuotations || 0}</div>,
    },
    {
      title: "Status",
      key: "status",
      render: (record) => (
        <div>
          <RFQStatusTags status={getStatus(record)} />
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: moreActionsRenderer,
    },
  ], [moreActionsRenderer]);

  // if (isExporter(userData)) {
  //   columns.splice(4, 0, {
  //     title: "Party Name",
  //     key: "targetedBusinessType",
  //     width: "150px",
  //     render: (record) => {
  //       return (
  //         <div>
  //           {isArrayNonEmpty(record?.targetedBusinessType)
  //             ? BUSINESS_TYPE_LABEL[record?.targetedBusinessType[0]]
  //             : "-"}
  //         </div>
  //       );
  //     },
  //   });
  // }
  let description = isBuyer(userData)
    ? `You can raise new RFQ(Request for Quotation) to get Competitive Quotes from Exporters.`
    : `  You can raise new RFQ’s (Request for Quotation) to get
                Competitive Quotes from Multiple Manufacturers, Commission
                Agents, CHA’s, Freight Forwarders and Shipping Lines.`;

  const renderContent = useCallback((data, count) => {
    return isMobile ? (
      <Card className="mobile-view">
        {data.map((record) => {
          return (
            <ItemDetailMobile
              postedDate={formatDate(record.createdAt)}
              primaryRow={{
                text: record.productName,
                subText: `${record.qty} ${record.units}`
              }}
              secondaryRow={{
                text: BUSINESS_TYPE_LABEL[record?.targetedBusinessType[0]],
                subText: `${record?.totalQuotations || 0} quotations recd.`
              }}
              status={getStatus(record)}
              StatusComponent={RFQStatusTags}
              MoreActions={() => moreActionsRenderer(record)}
              handleNavigation={() => {
                navigateToDetails(record.id)
              }}
            />
          )
        })}
      </Card>
    ) : (
      <Table
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        className="gx-table-responsive rfq-table myexim-table"
        columns={columns}
        dataSource={data}
        pagination={{
          position: ["bottomRight"],
          showSizeChanger: true,
          pageSize: pageSize,
          total: count,
          onChange: onShowSizeChange,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        onRow={(record) => {
          return {
            onClick: (e) => {
              // Add specific conditions here for menu items
              if (
                !e.target.classList.contains(
                  "ant-dropdown-menu-title-content"
                )
              ) {
                navigateToDetails(record?.id);
              }
            },
          };
        }}
        footer={() =>
          isArrayNonEmpty(data) && isBuyer(userData) ? (
            <div
              style={{
                padding: "10px 0",
                fontWeight: "bold",
                borderTop: "1px solid #d9d9d9",
              }}
            >
              Your RFQ(Request for Quotation) with "Pending Approval" Status
              requires to be reviewed by our Team and it will be
              Approved/Rejected with in 24 hours.
            </div>
          ) : null
        }
      />
    )
  }, [columns, isMobile, moreActionsRenderer, navigateToDetails, onShowSizeChange, pageSize, userData]);

  return (
    <>
      <Card
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="myexim-table-title">{`My RFQs ${isMobile ? '' : '(Request for Quotation)'}`}</div>
            {isArrayNonEmpty(RFQList?.rows) && (
              <Button type="primary" onClick={() => navigateToDetails()}>
                + Create New RFQ
              </Button>
            )}
          </div>
        }
        style={{ width: "100%" }}
      >
        <Meta
          description={
            <div style={{ lineHeight: 1.5 }}>
              <span style={{ color: "#1677ff" }}>{description}</span>
            </div>
          }
        />
      </Card>
      <Modal
        title="Confirmation"
        visible={showDeleteModel}
        onCancel={handleCancelModel}
        centered // Center the modal vertically
        footer={null} // Hide the default footer
      >
        <div className={"delete-modal-content"}>
          <ExclamationCircleOutlined className={"delete-icon"} size={40} />{" "}
          {/* Delete warning icon */}
          <p>Do you want to delete this item?</p>
          <Button type="default" onClick={() => handleCancelModel()}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => handleOnREQIconClick()}>
            Ok
          </Button>
        </div>
      </Modal>
      {isArrayNonEmpty(RFQList?.rows) ? renderContent(RFQList.rows, RFQList.count) : (
        !loading && (
          <ListEmptyComponent
            message="No RFQs Created Yet, Try it now"
            buttonText="Create New RFQ"
            onButtonClick={() => navigateToDetails()}
          />
        )
      )}
    </>
  );
};

export default RFQList;
