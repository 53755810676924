import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Card,
  message,
  Badge,
  Radio,
} from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { Button, Checkbox, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Option } from "antd/es/mentions";
import { axiosInstance, BASE_API_CONFIG } from "../../util/Api";
import {
  getLabel,
  isArrayNonEmpty,
  isValidElement,
  isValidString,
} from "../../util/ApiHelper";
import { MY_EXIM_API_RFQ } from "../../appRedux/services/requestQuotationsNetwork";
import { BUSINESS_TYPE_FOR_MESSAGE, RFQStatus } from "../../util/Constant";
import Upload from "../../components/Upload";
import { showAlert } from "../../util/helper";
import { labelComponent } from "./../../util/UtilComponents";
import { InfoCircleOutlined } from "@ant-design/icons";
import { LeftOutlined } from "@ant-design/icons";
import { fetchError, fetchStart, fetchSuccess } from "../../appRedux/actions";
import { isEnquiryPublished, isBuyer, isExporter } from "./../../util/helper";
import { BUSINESS_TYPE } from "./../../util/Constant";
import { CustomSuccessMessage } from "./../../util/CustomMessage";
import { startCase } from "lodash";
import { getProductsAction } from "../../appRedux/actions/myProductsAction";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";

const { TextArea } = Input;

const shipmentTypes = [
  { label: "FCL (Full Container Load)", value: "fcl" },
  { label: "LCL (Less Container Load)", value: "lcl" },
];
const MyForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = useForm();
  const history = useHistory();
  const { isMobile } = useGetBreakpoints();
  const authUser = useSelector(({ auth }) => auth.userData);
  const productList = useSelector((state) => state.myProducts.productsList);

  const [fileList, setFileList] = useState([{ src: "" }]);
  const [hsCodeData, setHsCodeData] = useState(null);
  const [hsCodeId, setHsCodeId] = useState(null);

  const [selectedSourceCountry, setSelectedSourceCountry] = useState(
    isBuyer(authUser) || isExporter(authUser) ? "India" : null
  );
  const [sourcePortsData, setSourcePortsData] = useState(null);
  const [selectedDestinationCountry, setSelectedDestinationCountry] =
    useState(null);
  const [destinationPortsData, setDestinationPortsData] = useState(null);

  const [noOfContainers, setNoOfContainers] = useState(1);

  const categoryList = useSelector((state) => state.myProducts.categoryList);
  const hsCodeList = useSelector((state) => state.myProducts.hsCodeList || []);
  const countries = useSelector(({ common }) => common.countries);
  const ports = useSelector(({ common }) => common.ports);
  const [targetedBusinessType, setTargetedBusinessType] = useState(null);

  const [filteredHsCodeData, setFilteredHsCodeData] = useState(null);

  const productsUnitList = useSelector(
    ({ myProducts }) => myProducts.productsUnitList
  );
  const [RFQFormStatus, setRFQFormStatus] = useState(null);
  const location = useLocation();
  const path = location.pathname;
  const hasCloneParam = path.includes("clone");
  const { loading } = useSelector(({ common }) => common);
  const isCHABusinessType =
    targetedBusinessType?.[0] === BUSINESS_TYPE.CUSTOM_HOUSE_AGENT;

  const setDataToForm = useCallback(
    (data) => {
      form.setFieldsValue({
        productCategory: data?.categories.id,
        hsCode: data?.hscode.code ?? "",
        productName: data?.productName,
        quantityRequired: data?.qty,
        uom: data?.units,
        productSpecification: data?.description,
        sourceCountry: data?.sourceCountry,
        sourcePort: data?.sourcePort,
        destinationCountry: data?.destinationCountry,
        destinationPort: data?.destinationPort,
        containerType: data.containerType,
        noOfContainers: data.noOfContainers,
        netWeightPerContainer: data.netWeightPerContainer,
        parties: data.targetedBusinessType?.[0],
        paymentTerms: data?.paymentTerms,
        shipmentMode: data?.shipmentMode,
        shipmentType: data?.shipmentType,
        cargoWeight: data?.cargoWeight,
        totalCubicMeter: data?.totalCubicMeter,
        locationForDelivery: data?.locationForDelivery,
      });
      // setTargetedBusinessType(data.targetedBusinessType)
      setHsCodeId(data?.hsCodeId);
      setSelectedDestinationCountry(data?.destinationCountry);
      setSelectedSourceCountry(data?.sourceCountry);
      if (
        data?.productImages?.length < 4 &&
        !isEnquiryPublished(RFQFormStatus)
      ) {
        const images = data?.productImages;
        images.push({ src: "" });
        setFileList([...images]);
      } else if (isArrayNonEmpty(data?.productImages)) {
        setFileList([...data?.productImages]);
      }
    },
    [RFQFormStatus, form]
  );

  useEffect(() => {
    if (isBuyer(authUser) || isExporter(authUser)) {
      form.setFieldValue("sourceCountry", "India");
      setTargetedBusinessType([...[BUSINESS_TYPE.EXPORTER]]);
    }
  }, [authUser]);

  useEffect(() => {
    if (!isArrayNonEmpty(productList)) {
      dispatch(getProductsAction());
    }
  }, [dispatch]);

  useEffect(() => {
    if (isValidElement(id)) {
      dispatch(fetchStart());
      MY_EXIM_API_RFQ.getRequestQuotationDetails({ id })
        .then((response) => {
          setDataToForm(response?.data?.data);
          setTargetedBusinessType([
            ...response?.data?.data?.targetedBusinessType,
          ]);
          setRFQFormStatus(response?.data?.data?.status);
          dispatch(fetchSuccess());
        })
        .catch((e) => {
          showAlert(e?.message);
          dispatch(fetchSuccess());
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const paymentTerms = Form.useWatch("paymentTerms", form);
  const parties = Form.useWatch("parties", form);
  const shipmentMode = Form.useWatch("shipmentMode", form);
  const shipmentType = Form.useWatch("shipmentType", form);

  useEffect(() => {
    if (
      targetedBusinessType?.[0] === BUSINESS_TYPE.FREIGHT_FORWARDER ||
      targetedBusinessType?.[0] === BUSINESS_TYPE.SHIPPING_LINE ||
      targetedBusinessType?.[0] === BUSINESS_TYPE.CUSTOM_HOUSE_AGENT
    ) {
      if (targetedBusinessType?.[0] === BUSINESS_TYPE.FREIGHT_FORWARDER) {
        form.setFieldValue("shipmentMode", "air");
      } else {
        form.setFieldValue("shipmentMode", "sea");
        form.setFieldValue("shipmentType", "fcl");
      }
    }
  }, [targetedBusinessType?.[0]]);

  let isEditDisabled = false;
  let isEdit = !hasCloneParam && isValidElement(id);

  const onFinish = (values, isDraftClick = false) => {
    let formData = isDraftClick ? form?.getFieldsValue() : values;
    let data = {
      productName: formData?.productName,
      description: formData?.productSpecification || "",
      qty: parseInt(formData?.quantityRequired) || "",
      categoryId: parseInt(formData?.productCategory),
      sourceCountry: formData?.sourceCountry || "",
      destinationCountry: formData?.destinationCountry || "",
      hsCodeId: hsCodeId,
      units: formData?.uom,
      destinationPort: formData?.destinationPort || "",
      isDuplicate: hasCloneParam,
      businessTypeId: authUser?.businessTypeId,
      userId: authUser?.id,
      status: isDraftClick ? RFQStatus?.DRAFT : RFQStatus?.PENDING_APPROVAL,
      productImages: [...fileList.filter((file) => file?.src)],
      remarks: "",
      targetedBusinessType: targetedBusinessType,
      containerType: formData?.containerType,
      noOfContainers: formData?.noOfContainers,
      netWeightPerContainer: formData?.netWeightPerContainer,
      sourcePort: formData?.sourcePort,
      paymentTerms: formData?.paymentTerms,
      locationForDelivery: formData?.locationForDelivery,
      shipmentMode: formData?.shipmentMode,
      shipmentType: formData?.shipmentType,
      cargoWeight: formData?.cargoWeight,
      totalCubicMeter: formData?.totalCubicMeter,
    };
    updatedDetailsToNetwork(data);
  };

  const onUpload = (data) => {
    setFileList((prevFileList) => {
      const updatedList = [...prevFileList];
      updatedList[data.index].src = data.src;
      if (updatedList.length < 4) {
        updatedList.push({ src: "" });
      }
      updatedList[data.index].originalName = data.originalName;
      return [...updatedList];
    });
  };
  const onRemove = (data) => {
    setFileList((prevFileList) => {
      const updatedList = [...prevFileList];
      updatedList[data.index].src = "";
      return updatedList;
    });
  };

  const updatedDetailsToNetwork = (data) => {
    let url = isEdit
      ? `${BASE_API_CONFIG.baseURL}/enquiries/${id}`
      : `${BASE_API_CONFIG.baseURL}/enquiries`;
    axiosInstance
      .post(url, data)
      .then((r) => {
        let quoteSendTo =
          BUSINESS_TYPE_FOR_MESSAGE[data?.targetedBusinessType[0]] ??
          "Exporters";
        if (data.status !== RFQStatus.DRAFT) {
          message.success({
            duration: 10,
            icon: <></>,
            content: (
              <CustomSuccessMessage
                title={`Your Enquiry is Submitted for Authorization.`}
                message={`You will start getting the Quotations directly from ${quoteSendTo} after your Enquiry is Authorized. We will notify you when ${quoteSendTo} sends the Quotations.`}
              />
            ),
            className: "custom-success-message",
          });
        }
        history.goBack();
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  const uploadComponent = (isDisabled) => {
    return (
      <div className="attachments">
        <div className="sub-header">Attachments</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {fileList.map((file, index) => {
            return (
              <Upload
                isDisabled={isDisabled}
                index={index}
                src={file.src}
                id={index}
                label={
                  isValidString(file?.label) ? file?.label : getLabel(index)
                }
                originalName={file.originalName}
                onUpload={onUpload}
                onRemove={onRemove}
              />
            );
          })}
        </div>
      </div>
    );
  };
  const handleChangeProductCategory = (value) => {
    form.resetFields(["hsCode"]);
    setHsCodeId(null);
    setFilteredHsCodeData(null);
    setHsCodeData(hsCodeList?.filter((item) => item?.categories?.id === value));
  };

  useEffect(() => {
    const countryId = countries.find(
      (item) => item.country === selectedSourceCountry
    )?.id;
    setSourcePortsData(ports.filter((item) => item.countryId === countryId));
  }, [countries, ports, selectedSourceCountry]);

  const handleChangeSourceCountry = (value) => {
    setSelectedSourceCountry(value);
    form.resetFields(["sourcePort"]);
  };

  useEffect(() => {
    const countryId = countries.find(
      (item) => item.country === selectedDestinationCountry
    )?.id;
    setDestinationPortsData(
      ports.filter((item) => item.countryId === countryId)
    );
  }, [countries, ports, selectedDestinationCountry]);

  const handleChangeDestinationCountry = (value) => {
    setSelectedDestinationCountry(value);
    form.resetFields(["destinationPort"]);
  };

  const validatePortSelection = async (_, value) => {
    const sourcePort = form.getFieldValue("sourcePort");
    if (value === sourcePort) {
      throw new Error("Source Port and Destination Port cannot be the same");
    }
    return;
  };

  const handleHSCodeSearch = (value) => {
    const trimmedValue = value.trim();
    const containsNumbers = /^\d+$/.test(trimmedValue.toLowerCase());

    setFilteredHsCodeData(() => {
      const filterFunction = (option) => {
        const codeString = option.code
          .toString()
          .replace(/\s+/g, "")
          .toLowerCase();
        const descriptionString = option.description
          .replace(/[\n\r"]/g, "")
          .toLowerCase();

        return containsNumbers
          ? codeString.includes(trimmedValue.toLowerCase())
          : descriptionString.includes(trimmedValue.toLowerCase());
      };

      return isValidElement(hsCodeData)
        ? hsCodeData.filter(filterFunction)
        : hsCodeList?.filter(filterFunction);
    });
  };

  const onBusinessTypeChange = (value) => {
    setTargetedBusinessType([value]);
  };

  const validateTextArea = (_, value) => {
    if (value && value.length > 1000) {
      return Promise.reject(
        new Error("Product Requirements cannot exceed 1000 characters")
      );
    }
    return Promise.resolve();
  };
  const showFormBasedOnBusinessType = (selectedBusinessType) => {
    const BusinessFieldsData = [
      {
        BusinessType: "exporter",
        fields: {
          productCategory: true,
          hsCode: true,
          productName: true,
          quantityRequired: true,
          uom: true,
          productSpecification: true,
          sourceCountry: true,
          destinationCountry: true,
          destinationPort: true,
          attachment: true,
          shipmentMode: false,
          shipmentType: false,
          deliveryTerms: false,
        },
      },
      {
        BusinessType: "manufacturer",
        fields: {
          productCategory: true,
          hsCode: true,
          productName: true,
          quantityRequired: true,
          uom: true,
          productSpecification: true,
          sourceCountry: false,
          sourcePort: false,
          destinationCountry: false,
          destinationPort: false,
          containerType: false,
          noOfContainers: false,
          netWeightPerContainer: false,
          attachment: true,
          shipmentMode: false,
          shipmentType: false,
          deliveryTerms: true,
        },
      },
      {
        BusinessType: "commission_agent",
        fields: {
          productCategory: true,
          hsCode: true,
          productName: true,
          quantityRequired: true,
          uom: true,
          productSpecification: true,
          sourceCountry: false,
          sourcePort: false,
          destinationCountry: false,
          destinationPort: false,
          containerType: false,
          noOfContainers: false,
          netWeightPerContainer: false,
          attachment: true,
          shipmentMode: false,
          shipmentType: false,
          deliveryTerms: true,
        },
      },
      {
        BusinessType: "custom_house_agent",
        fields: {
          productCategory: true,
          hsCode: true,
          productName: true,
          quantityRequired: false,
          uom: false,
          productSpecification: true,
          sourceCountry: true,
          sourcePort: true,
          destinationCountry: true,
          destinationPort: true,
          containerType: true,
          noOfContainers: true,
          netWeightPerContainer: true,
          attachment: false,
          deliveryTerms: false,
          shipmentMode: true,
          shipmentType: true,
        },
      },
      {
        BusinessType: "freight_forwarder",
        fields: {
          productCategory: true,
          hsCode: true,
          productName: true,
          quantityRequired: false,
          uom: false,
          productSpecification: true,
          sourceCountry: true,
          sourcePort: true,
          destinationCountry: true,
          destinationPort: true,
          containerType: true,
          noOfContainers: true,
          netWeightPerContainer: true,
          attachment: false,
          deliveryTerms: false,
          shipmentMode: true,
          shipmentType: true,
        },
      },
      {
        BusinessType: "shipping_line",
        fields: {
          productCategory: true,
          hsCode: true,
          productName: true,
          quantityRequired: false,
          uom: false,
          productSpecification: true,
          sourceCountry: true,
          sourcePort: true,
          destinationCountry: true,
          destinationPort: true,
          containerType: true,
          noOfContainers: true,
          netWeightPerContainer: true,
          attachment: false,
          deliveryTerms: false,
          shipmentMode: true,
          shipmentType: true,
        },
      },
    ];
    const selectedFields =
      BusinessFieldsData.find(
        (business) => business.BusinessType === selectedBusinessType?.[0]
      )?.fields || {};

    return selectedFields;
  };
  console.log("shipmentMode==>", shipmentMode, shipmentType);

  const shipmentModes = [
    {
      label: "Sea",
      value: "sea",
    },
    {
      label: "Air",
      value: "air",
    },
  ];
  return (
    !loading && (
      <Card className="myexim-detail-card">
        <div className="myexim-table-title">
          <LeftOutlined
            className={"back-icon"}
            size="large"
            onClick={() => {
              history.goBack();
            }}
          />
          {isEnquiryPublished(RFQFormStatus) && !hasCloneParam
            ? "Enquiry Details"
            : isEdit
            ? "Edit Enquiry"
            : "Create Enquiry"}
        </div>

        <Form form={form} onFinish={onFinish} className="gx-pt-4">
          <Row gutter={16}>
            {isExporter(authUser) && (
              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={
                    "Select the Party who you would like to request quote from"
                  }
                  name="parties"
                  rules={[
                    {
                      required: true,
                      message: "Party is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Party"
                    onChange={(selectedParty) =>
                      onBusinessTypeChange(selectedParty)
                    }
                  >
                    <Option value={BUSINESS_TYPE.MANUFACTURER}>
                      Manufacturers
                    </Option>
                    <Option value={BUSINESS_TYPE.COMMISSION_AGENT}>
                      Commission Agents
                    </Option>
                    <Option value={BUSINESS_TYPE.CUSTOM_HOUSE_AGENT}>
                      Custom House Agent(CHA)
                    </Option>
                    <Option value={BUSINESS_TYPE.FREIGHT_FORWARDER}>
                      Freight Forwarders
                    </Option>
                    <Option value={BUSINESS_TYPE.SHIPPING_LINE}>
                      Shipping Lines
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
            {showFormBasedOnBusinessType(targetedBusinessType)[
              "productName"
            ] && (
              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={labelComponent("Product Name", null)}
                  name="productName"
                  rules={[
                    {
                      required: true,
                      message: "Product Name is required",
                    },
                  ]}
                >
                  <Input
                    disabled={isEditDisabled}
                    placeholder="Product Name"
                    size={"large"}
                    showCount
                    maxLength={100}
                  />
                </Form.Item>
              </Col>
            )}
            {showFormBasedOnBusinessType(targetedBusinessType)[
              "deliveryTerms"
            ] &&
              (parties === BUSINESS_TYPE.MANUFACTURER ||
                parties === BUSINESS_TYPE.COMMISSION_AGENT) && (
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label={"Delivery Terms"}
                    name="paymentTerms"
                    rules={[
                      {
                        required: true,
                        message: "Delivery Terms is required",
                      },
                    ]}
                  >
                    <Select placeholder="Select Delivery Term">
                      <Option value={"EXW"}>EXW - Ex Works</Option>
                      <Option value={"EXM"}>EXM - Ex Mill</Option>
                      <Option value={"FOR"}>FOR</Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
            {showFormBasedOnBusinessType(targetedBusinessType)[
              "deliveryTerms"
            ] &&
              paymentTerms == "FOR" && (
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.paymentTerms !== currentValues.paymentTerms
                    }
                    disabled={isEditDisabled}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label={labelComponent("Location for delivery")}
                    name="locationForDelivery"
                    rules={[
                      {
                        validator: validateTextArea,
                      },
                      {
                        required: true,
                        message: "location for delivery is required",
                      },
                    ]}
                  >
                    <TextArea
                      disabled={isEditDisabled}
                      placeholder={
                        "Provide the location for delivery, so that suppliers can send appropriate quotations"
                      }
                      showCount
                      maxLength={250}
                    />
                  </Form.Item>
                </Col>
              )}

            {showFormBasedOnBusinessType(targetedBusinessType)[
              "quantityRequired"
            ] && (
              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  disabled={isEditDisabled}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={labelComponent("Quantity Required", null)}
                  name="quantityRequired"
                  rules={[
                    {
                      required: true,
                      message: "Quantity is required",
                    },
                  ]}
                >
                  <Input
                    disabled={isEditDisabled}
                    placeholder="Required Quantity"
                    size={"large"}
                    type="number"
                  />
                </Form.Item>
              </Col>
            )}
            {showFormBasedOnBusinessType(targetedBusinessType)["uom"] && (
              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  disabled={isEditDisabled}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={labelComponent("Unit of Measurement (UOM)", null)}
                  name="uom"
                  rules={[
                    {
                      required: true,
                      message: "UOM is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select Unit of Measurement"
                    disabled={isEditDisabled}
                  >
                    {productsUnitList?.map((option) => (
                      <Option key={option.label} value={option.label}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {showFormBasedOnBusinessType(targetedBusinessType)[
              "productCategory"
            ] && (
              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={labelComponent(
                    "Product Category",
                    `Products Categories displayed here is based on Approved Products under My Products.`
                  )}
                  name="productCategory"
                  rules={[
                    {
                      required: true,
                      message: "Product Category is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    size="large"
                    name={"productCategory"}
                    placeholder="Select Product Category"
                    disabled={isEditDisabled}
                    onChange={handleChangeProductCategory}
                    dropdownStyle={{ maxHeight: "200px", overflowY: "auto" }}
                    filterOption={(input, option) => {
                      const children = option?.children ?? "";
                      const optionText = children.props?.children ?? "";
                      return optionText
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {productList?.rows?.map(
                      (option) =>
                        option?.status === "approved" && (
                          <Select.Option
                            key={option.id}
                            value={option?.categories?.id}
                          >
                            <div style={{ whiteSpace: "normal" }}>
                              {startCase(option?.categories?.name)}
                            </div>
                          </Select.Option>
                        )
                    )}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {showFormBasedOnBusinessType(targetedBusinessType)["hsCode"] && (
              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  disabled={isEditDisabled}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={labelComponent(
                    "HS Code / Item Description",
                    isCHABusinessType
                      ? "Select appropriate HS Code to get the Quotation from Right Partiers."
                      : `If you are not aware of Product Category and HS Code, Please refer DGFT website https://www.dgft.gov.in/CP/ and look for "Select your ITC(HS) Code / Product Selection".`
                  )}
                  help={labelComponent(
                    <span style={{ color: "#1677ff" }}>
                      Note: We are not responsible for any country-specific
                      restrictions for the selected product; please ensure you
                      review Source and Destination country’s regulations before
                      making an inquiry
                    </span>
                  )}
                  name="hsCode"
                  rules={[
                    {
                      required: true,
                      message: "HS code is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    size="large"
                    name="hsCode"
                    placeholder="Select HS code / Item Description"
                    filterOption={false}
                    dropdownStyle={{ maxHeight: "200px", overflowY: "auto" }}
                    disabled={isEditDisabled}
                    onChange={(value) => {
                      setHsCodeId(value);
                    }}
                    onSearch={handleHSCodeSearch}
                  >
                    {isValidElement(filteredHsCodeData)
                      ? filteredHsCodeData?.map((option) => (
                          <Option key={option.id} value={option.id}>
                            <div style={{ whiteSpace: "normal" }}>
                              {option.code} -{" "}
                              {option.description.replace(/[\n\r"]/g, "")}
                            </div>
                          </Option>
                        ))
                      : hsCodeData?.map((option) => (
                          <Option key={option.id} value={option.id}>
                            <div style={{ whiteSpace: "normal" }}>
                              {option.code} -{" "}
                              {option.description.replace(/[\n\r"]/g, "")}
                            </div>
                          </Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {showFormBasedOnBusinessType(targetedBusinessType)[
              "productSpecification"
            ] && (
              <Col span={24}>
                <Form.Item
                  disabled={isEditDisabled}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={labelComponent(
                    "Requirements",
                    isCHABusinessType
                      ? "Provide your detail requirements to get the right Quotations."
                      : "Provide the detail requirement including the product specification to get the detailed quotations"
                  )}
                  name="productSpecification"
                  rules={[
                    {
                      validator: validateTextArea,
                    },
                    {
                      required: true,
                      message: "Requirements is required",
                    },
                  ]}
                >
                  <TextArea
                    disabled={isEditDisabled}
                    rows={10}
                    placeholder={
                      "Provide the detail requirement including the product specification, so that suppliers can send appropriate quotations"
                    }
                    showCount
                    maxLength={250}
                  />
                </Form.Item>
              </Col>
            )}

            {showFormBasedOnBusinessType(targetedBusinessType)[
              "sourceCountry"
            ] && (
              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={labelComponent(
                    "Loading Country",
                    "Currently we are allowing Loading country as India only"
                  )}
                  name="sourceCountry"
                  rules={[
                    {
                      required: !isBuyer(authUser) || !isExporter(authUser),
                      message: "Loading Country is required",
                    },
                  ]}
                >
                  <Select
                    defaultValue={
                      isBuyer(authUser) || isExporter(authUser) ? "India" : null
                    }
                    disabled={
                      isEditDisabled ||
                      isBuyer(authUser) ||
                      isExporter(authUser)
                    }
                    showSearch
                    size="large"
                    onChange={handleChangeSourceCountry}
                    value={selectedSourceCountry}
                    placeholder="Select Loading Country"
                  >
                    {countries?.map(
                      (option) =>
                        option.country !== "" && (
                          <Option key={option.country} value={option.country}>
                            {option.country}
                          </Option>
                        )
                    )}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {showFormBasedOnBusinessType(targetedBusinessType)[
              "sourcePort"
            ] && (
              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  disabled={isEditDisabled}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={labelComponent(
                    "Loading Port",
                    "Select the Loading Port where Goods to be loaded"
                  )}
                  name="sourcePort"
                  rules={[
                    {
                      required: true,
                      message: "Loading Port is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select Loading Port"
                    disabled={!selectedSourceCountry}
                  >
                    {isValidElement(sourcePortsData)
                      ? sourcePortsData?.map((option) => (
                          <Option key={option.port} value={option.port}>
                            {option.port}
                          </Option>
                        ))
                      : ports?.map((option) => (
                          <Option key={option.port} value={option.port}>
                            {option.port}
                          </Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {showFormBasedOnBusinessType(targetedBusinessType)[
              "destinationCountry"
            ] && (
              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  disabled={isEditDisabled}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={labelComponent(
                    "Destination Country",
                    "Select the Destination Country where Goods to be Delivered"
                  )}
                  name="destinationCountry"
                  rules={[
                    {
                      required: true,
                      message: "Destination Country is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select Destination Country"
                    onChange={handleChangeDestinationCountry}
                    value={selectedDestinationCountry}
                  >
                    {countries?.map((option) => (
                      <Option key={option.country} value={option.country}>
                        {option.country}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {showFormBasedOnBusinessType(targetedBusinessType)[
              "destinationPort"
            ] && (
              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  disabled={isEditDisabled}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={labelComponent(
                    "Destination Port",
                    "Select the Destination Port where Goods to be Delivered"
                  )}
                  name="destinationPort"
                  rules={[
                    {
                      required: true,
                      message: "Destination Port is required",
                    },
                    { validator: validatePortSelection },
                  ]}
                >
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select Destination Port"
                    disabled={!selectedDestinationCountry}
                    name="destinationPort"
                    rules={[
                      {
                        required: true,
                        message: "Destination Port is required",
                      },
                    ]}
                  >
                    {isValidElement(destinationPortsData)
                      ? destinationPortsData?.map((option) => (
                          <Option key={option.port} value={option.port}>
                            {option.port}
                          </Option>
                        ))
                      : ports?.map((option) => (
                          <Option key={option.port} value={option.port}>
                            {option.port}
                          </Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            {showFormBasedOnBusinessType(targetedBusinessType)[
              "shipmentMode"
            ] && (
              <Col span={24}>
                <Form.Item
                  disabled={isEditDisabled}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={labelComponent("Shipment mode")}
                  name="shipmentMode"
                  rules={[
                    {
                      required: true,
                      message: "Shipment mode is required",
                    },
                  ]}
                >
                  <Radio.Group
                    defaultValue={"sea"}
                    options={shipmentModes}
                    onChange={(value) => {}}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </Col>
            )}
            {showFormBasedOnBusinessType(targetedBusinessType)[
              "shipmentMode"
            ] &&
              shipmentMode === "sea" && (
                <Col span={24}>
                  <Form.Item
                    disabled={isEditDisabled}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label={labelComponent("Shipment type")}
                    name="shipmentType"
                    rules={[
                      {
                        required: true,
                        message: "Shipment mode is required",
                      },
                    ]}
                  >
                    <Radio.Group
                      defaultValue={"lcl"}
                      options={shipmentTypes}
                      onChange={(value) => {}}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Form.Item>
                </Col>
              )}
            {showFormBasedOnBusinessType(targetedBusinessType)[
              "shipmentMode"
            ] &&
              (shipmentMode === "air" || shipmentType === "lcl") && (
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    disabled={isEditDisabled}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label={labelComponent("Cargo Weight (In Kilograms)", null)}
                    name="cargoWeight"
                    rules={[
                      {
                        required: true,
                        message: "Cargo Weight is required",
                      },
                    ]}
                  >
                    <Input
                      disabled={isEditDisabled}
                      placeholder="Cargo Weight (In Kilograms)"
                      size={"large"}
                      type="number"
                    />
                  </Form.Item>
                </Col>
              )}
            {showFormBasedOnBusinessType(targetedBusinessType)[
              "shipmentMode"
            ] &&
              (shipmentMode === "air" || shipmentType === "lcl") && (
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    disabled={isEditDisabled}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label={labelComponent("Total Cubic Meters (m^3)", null)}
                    name="totalCubicMeter"
                    rules={[
                      {
                        required: true,
                        message: "Total Cubic Meters is required",
                      },
                    ]}
                  >
                    <Input
                      disabled={isEditDisabled}
                      placeholder="Total Cubic Meters (m^3)"
                      size={"large"}
                      type="number"
                    />
                  </Form.Item>
                </Col>
              )}
            {showFormBasedOnBusinessType(targetedBusinessType)[
              "containerType"
            ] &&
              shipmentMode === "sea" &&
              shipmentType === "fcl" && (
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    disabled={isEditDisabled}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Container Type"
                    name="containerType"
                    rules={[
                      {
                        required: true,
                        message: "Select Container type",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      size="large"
                      placeholder="Select Container type"
                      disabled={isEditDisabled}
                    >
                      <Option value={"20drystandard"}>20' Dry Standard</Option>
                      <Option value={"40drystandard"}>40' Dry Standard</Option>
                      <Option value={"40dryhighcube"}>40' Dry High Cube</Option>
                      <Option value={"45dryhighcube"}>45' Dry High Cube</Option>
                      <Option value={"20reefer"}>20' Reefer</Option>
                      <Option value={"40reeferhighcube"}>
                        40' Reefer High Cube
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}

            {showFormBasedOnBusinessType(targetedBusinessType)[
              "noOfContainers"
            ] &&
              shipmentMode === "sea" &&
              shipmentType === "fcl" && (
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label={"No. of Containers"}
                    name="noOfContainers"
                    rules={[
                      {
                        required: true,
                        message: "No. of Containers is required",
                      },
                    ]}
                  >
                    <InputNumber
                      value={noOfContainers}
                      disabled={isEditDisabled}
                      min={1}
                      defaultValue={0}
                      // addonBefore={
                      //   <div
                      //     style={{ cursor: "pointer" }}
                      //     onClick={decrementContainers}
                      //   >
                      //     -
                      //   </div>
                      // }
                      // addonAfter={
                      //   <div
                      //     style={{ cursor: "pointer" }}
                      //     onClick={incrementContainers}
                      //   >
                      //     +
                      //   </div>
                      // }
                    />
                  </Form.Item>
                </Col>
              )}
            {showFormBasedOnBusinessType(targetedBusinessType)[
              "netWeightPerContainer"
            ] &&
              shipmentMode === "sea" &&
              shipmentType === "fcl" && (
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label={"Net Weight Per Container (Kilograms)"}
                    name="netWeightPerContainer"
                    rules={[
                      {
                        required: true,
                        message: "Net Weight per Container is required",
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={isEditDisabled}
                      min={0}
                      defaultValue={0}
                      controls={false}
                    />
                  </Form.Item>
                </Col>
              )}
          </Row>
          <Row>
            {showFormBasedOnBusinessType(targetedBusinessType)[
              "attachment"
            ] && (
              <Col span={24}>
                <Form.Item
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 12 }}
                  name="attachment"
                  rules={[
                    {
                      required: isEdit ? false : true,
                      message: "Attachment is Required",
                    },
                  ]}
                >
                  {uploadComponent()}
                </Form.Item>
                <Col>
                  <div className="upload-tips gx-mt-4">
                    <div className="header" style={{ display: "flex" }}>
                      <InfoCircleOutlined />
                      &nbsp; Tips{" "}
                    </div>
                    <div className="desc">
                      <Badge
                        status="default"
                        text="Attach the Image / Video as reference to your requirement"
                      />
                      <br />
                      <Badge
                        status="default"
                        text="Supported file types: .jpg, .jpeg, .png .pdf"
                      />
                      <br />
                      <Badge
                        status="default"
                        text="File must not more than 5MB"
                      />
                      <br />
                      <Badge
                        status="default"
                        text="Make sure you have good resolution"
                      />
                      <br />
                      <Badge
                        status="default"
                        text="Photo resolution must be more than 600x600 resolution"
                      />
                      <br />
                    </div>
                  </div>
                </Col>
              </Col>
            )}
          </Row>
          <Row className="gx-pt-5">
            <Col span={24}>
              <Form.Item className="gx-text-center">
                <div
                  className="gx-mr-3"
                  style={{
                    justifyContent: isMobile ? "center" : "end",
                    display: "flex",
                    flex: 1,
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    type="secondary"
                    size="large"
                    style={{ width: 150 }}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                  {!isEditDisabled &&
                    RFQFormStatus !== RFQStatus.PENDING_APPROVAL && (
                      <Button
                        type="secondary"
                        size="large"
                        style={{ width: 150 }}
                        onClick={() => onFinish(form, true)}
                      >
                        Save as draft
                      </Button>
                    )}
                  {!isEditDisabled && (
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      style={{ width: 150 }}
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {/*)}*/}
      </Card>
    )
  );
};

export default MyForm;
